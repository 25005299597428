<template>
  <v-app id="inspire">
    <v-main>
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-card class="elevation-12">
              <v-toolbar
                color="primary"
                dark
                flat
              >
                <v-toolbar-title>Login form</v-toolbar-title>
                <v-spacer></v-spacer>

              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    label="Username"
                    name="userName"
                    prepend-icon="mdi-account"
                    type="text"
                    @input="updateField($event, 'userName')"
                  ></v-text-field>

                  <v-text-field
                    id="password"
                    label="Password"
                    name="password"
                    prepend-icon="mdi-lock"
                    type="password"
                    @input="updateField($event, 'password')"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="handleLogin()">Login</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-snackbar
      v-model="snackbar"
      :absolute=true
      :color=snackbarColour
    >
      {{ snackbarText }}
    </v-snackbar>
  </v-app>
</template>

<script>

import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  data: () => ({
    snackbar: false,
    snackbarText: "",
    snackbarColour: "",
    dropdown_status: [
      { value: true, text: "Banned" },
      { value: false, text: "Active" },
    ]
  }),
  computed: {
    ...mapGetters ({
      getUser: "auth/getUser"
    }),
    // userName: {
    //   get() {
    //     return this.getUser.userName
    //   },
    //   set(value) {
    //     this.updateUser({[userName]: value})
    //   }
    // },
    // password: {
    //   get() {
    //     return this.getUser.password
    //   },
    //   set(value) {
    //     this.updateUser({[password]: value})
    //   }
    // }
  },
  methods: {
    ...mapMutations ({
      updateUser: "auth/updateUser"
    }),
    ...mapActions ({
      login: "auth/login"
    }),
    updateField(value, fieldName) {
      this.updateUser({[fieldName]: value});
    },
    handleLogin() {
      this.login()
      .then((response) => {
        if(response.status == "OK")
          this.$router.push({name: "Home"})
        else
          this.showErrorMessage()
      })
    },
    showErrorMessage() {
      this.snackbarText = "Login failed, please check your credentials"
      this.snackbarColour = "red darken-2"
      this.snackbar = true
    }
  }
}
</script>