import apiRequest from "./apiRequest"

export default {
    banUser: (payload, headers) => {
        return apiRequest.post(window.apiUrl + "/user/ban", payload, headers)
    },
    fetchUserHistory: (payload) => {
        return apiRequest.get(window.apiUrl + "/user/history/" + payload, [])
    },
    fetchUserSubscriptions: (payload) => {
        return apiRequest.get(window.apiUrl + "/user/subscriptions/" + payload, [])
    },
    fetchBannedUser: (msisdn, headers) => {
        return apiRequest.get(window.apiUrl + "/user/banned/" + msisdn, headers)
    },
    test: (headers) => {
        return apiRequest.get(window.apiUrl + "test/test", headers)
    }
}