import authApi from '../plugins/api/authApi'

export default {
  namespaced: true,
  strict : true,

  state: {
    user: {},
  },
  getters: {
    getUser(state) {
      return state.user
    }
  },
  mutations: {
    setUser: (state, payload) => {
      state.user = payload
    },
    updateUser: (state, payload) => {
      state.user = Object.assign({}, state.user, payload);
    }
  },
  actions: {
    login: ({getters}) => {
      let data = {
        username: getters.getUser.userName,
        password: getters.getUser.password
      }
      return authApi.login(data, "")
      .then((response) => {
        return response
      })
      .catch((error) => {
        return error
      })
    },
  }
}