import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

import app from './app.js'
import user from './user.js'
import auth from './auth.js'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
    key: "astrobook-vodafone",
    storage: window.localStorage
})

export default new Vuex.Store({
    modules: {
        app,
        user,
        auth
    },
    plugins: [vuexLocal.plugin],
    strict: true
})