import axios from 'axios'
import mockRequest from '../api/mock/mockRequest'

export default {
    async request({method, url, payload, headers}) {

        if (!url) {
            throw new Error('url param is required');
        }
        method = method || 'GET';

        const init = {
            method: method,
            url: url
        }

        if (typeof headers !== 'undefined' && headers !== null) init.headers = headers

        if (typeof payload !== 'undefined' && payload !== null) init.data = payload

        return axios (init)
            .then (response => {
                return response.data;
            })
            .catch (error => {
                // endpoint does not exist, try to mock the response
                if (error.response.status === 404 || error.response.status === 405) {
                    return mockRequest.request({method, url, payload});
                }

                return error && error.response ? error.response.status : error;
            });
    },
    get(url, headers) {
        return this.request({method: 'GET', url: url, headers: headers})
    },
    post(url, payload, headers) {
        return this.request({method: 'POST', url: url, payload: payload, headers: headers})
    },
    postForm(url, data, headers) {
        const form = document.createElement('form');
        form.headers = headers;
        form.method = "POST";
        form.action = url;

        for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
            const hiddenField = document.createElement('input');
            hiddenField.type = 'hidden';
            hiddenField.name = key;
            hiddenField.value = data[key];

            form.appendChild(hiddenField);
            }
        }

        document.body.appendChild(form);
        form.submit();
    },
    postMultiPartForm(url, data, headers) {
        const payload = (typeof data == 'string') ? data : Object.keys(data).map((k) => {
            return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
        }).join('&')

        headers = headers || {}
        headers['Content-Type'] = 'application/x-www-form-urlencoded'

        return this.request({method: 'POST', url: url, payload: payload, headers: headers})
    },
    postJson(url, data, headers) {
        const payload = (typeof data == 'string') ? data : JSON.stringify(data)

        headers = headers || {}
        headers['Content-Type'] = 'application/json'

        return this.request({method: 'POST', url: url, payload: payload, headers: headers})
    },
    
}