<template>
<div>
    <v-layout>
        <v-card class="ma-4" max-width="300">
            <p class="text-lg-h6 pl-2">User</p>
            <v-text-field class="pa-2" label="Phone Number" placeholder="Enter the user's phone number" v-model="MSISDN" maxlength="12" @keyup="validateKey($event)"></v-text-field>

            <v-text-field class="pa-2" readonly label="Operator" placeholder="The user's operator" :value="getBannedUser.operator"></v-text-field>

            <v-textarea class="pa-2" rows="2" label="Comments" placeholder="Enter your comments here" :value="getBannedUser.comments" @input="updateField($event, 'comments')"></v-textarea>

            <v-select class="pa-2" readonly :items="dropdown_status" label="Status" :value="getBannedUser.isBanned" @input="updateField($event, 'isBanned')"></v-select>

            <v-card-actions>
                <v-btn text color="deep-purple accent-4" @click="clearFilters(); handleUserHistory()">Search</v-btn>
                <v-btn text color="red accent-4" @click="handleBannedUser()">Ban</v-btn>
            </v-card-actions>
        </v-card>

        <v-card class="ma-4" max-width="500">
            <p class="text-lg-h6 pl-2">Subscriptions</p>
            <v-data-table id="user-subscriptions-data-table" class="elevation-1" item-class="body-2" :headers="subscriptionHeaders" :items="getUserSubscriptions" item-key="id" :loading="loading">
                <template v-slot:item="props">
                    <tr>
                        <td class="text-xs-body-2">{{ props.item.keyword }}</td>
                        <td class="text-xs-body-2">{{ props.item.shortCode }}</td>
                        <td class="text-xs-body-2">{{ props.item.migrationDate }}</td>
                        <td class="text-xs-body-2">{{ props.item.unsubscriptionDate }}</td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>
    </v-layout>

    <v-data-table id="user-history-data-table" class="elevation-1" item-class="body-2" :headers="headers" :items="FilteredResults" item-key="id" :loading="loading" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :footer-props="{'items-per-page-options':[30, 60, 90, -1]}">
        <template v-slot:top>
            <v-container fluid>
                <v-row>
                    <v-toolbar v-show="export_json_show">
                        <p class="text-lg-h6 pl-2">User History - {{ MSISDN }}</p>
                        <v-spacer></v-spacer>
                        <v-btn color="deep-purple accent-4" dark class="mb-2">
                            <vue-excel-xlsx :data="export_json_data" :columns="export_json_fields" :sheetname="export_json_worksheet" :filename="export_json_filename">
                                EXPORT
                            </vue-excel-xlsx>
                        </v-btn>
                    </v-toolbar>
                </v-row>
                <v-row>
                    <v-col cols="3">
                        <v-row class="pa-6">
                            <v-select @change="Filter()" :items="ShortCodeFilterItems" v-model="filterShortCodesModel" multiple label="Short Code">
                            </v-select>
                        </v-row>
                    </v-col>
                    <v-col cols="3">
                        <v-row class="pa-6">
                            <v-select @change="Filter()" :items="deliveryStatusFilterItems" v-model="filterDeliveryStatusModel" multiple label="Status"></v-select>
                        </v-row>
                    </v-col>
                    <v-col cols="3">
                        <v-row class="pa-6">
                            <v-text-field v-model="filterApplication" type="text" label="Application"></v-text-field>
                        </v-row>
                    </v-col>
                    <v-col cols="3">
                        <v-row class="pa-6">
                            <v-text-field v-model="filterCompany" type="text" label="Company"></v-text-field>
                        </v-row>
                    </v-col>
                </v-row>
                <v-btn v-if="!filtersClear" @click="clearFilters()">Clear</v-btn>
            </v-container>
        </template>

        <template v-slot:item="props">
            <tr>
                <td>{{ props.item.type }}</td>
                <td class="main-column">{{ props.item.message }}</td>
                <td class="user-history-data-table">{{ props.item.keyword }}</td>
                <td v-bind:class="{ 'green--text': props.item.pricePoint == '0' }">
                    {{ props.item.shortCode }}
                </td>
                <td class="main-column">{{ props.item.dateSent }}</td>
                <td class="main-column">{{ props.item.deliveryStatus }}</td>
                <td class="main-column">{{ props.item.dateDelivered }}</td>
                <td class="sub-column">{{ props.item.errorCode }}</td>
                <td class="main-column">{{ props.item.application }}</td>
                <td class="text-xs-body-2">{{ props.item.company }}</td>
            </tr>
        </template>
    </v-data-table>

    <v-snackbar v-model="snackbar" :absolute="true" :color="snackbarColour">
        {{ snackbarText }}
    </v-snackbar>

    <go-top :size=45 :right=20 bg-color="rgba(170, 187, 183, 0.62)">
    </go-top>

</div>
</template>

<script>
import {
    mapGetters,
    mapMutations,
    mapActions
} from "vuex";
import GoTop from '@inotom/vue-go-top';

export default {
    components: {
        GoTop
    },
    data: () => ({

        sortBy: 'dateSent',
        sortDesc: true,

        ShortCodeFilterItems: [],
        FilteredResults: [],

        FilterShortCodesBool: false,
        FilterDeliveryStatusBool: false,

        export_json_fields: [{
                label: "Traffic",
                field: "type"
            },
            {
                label: "Message",
                field: "message"
            },
            {
                label: "Keyword",
                field: "keyword"
            },
            {
                label: "Short Code",
                field: "shortCode"
            },
            {
                label: "Date Sent",
                field: "dateSent"
            },
            {
                label: "Status",
                field: "deliveryStatus"
            },
            {
                label: "Date Delivered",
                field: "dateDelivered"
            },
            {
                label: "Status Description",
                field: "errorCode"
            },
            {
                label: "Application",
                field: "application"
            },
            {
                label: "Company",
                field: "company"
            },
        ],
        export_json_data: [],
        export_json_worksheet: "export",
        export_json_filename: "export.xlsx",
        export_json_show: false,
        loading: false,
        snackbar: false,
        snackbarText: "",
        snackbarColour: "",
        isFormValid: false,
        dropdown_status: [{
                value: true,
                text: "Banned"
            },
            {
                value: false,
                text: "Active"
            },
        ],
        deliveryStatusFilterItems: [],
        filterApplication: "",
        filterCompany: "",
    }),
    computed: {
        ...mapGetters({
            getMSISDN: "user/getMSISDN",
            getBannedUser: "user/getBannedUser",
            getUserHistory: "user/getHistory",
            getUserSubscriptions: "user/getSubscriptions",
        }),
        MSISDN: {
            get() {
                return this.getMSISDN;
            },
            set(payload) {
                this.setMSISDN(payload);
            },
        },
        filtersClear: {
            get() {
                return (
                    this.filterShortCode == "" &&
                    this.filterDeliveryStatus == null &&
                    this.filterApplication == "" &&
                    this.filterCompany == ""
                );
            },
        },
        headers() {
            return [{
                    text: "Traffic",
                    align: "left",
                    sortable: true,
                    value: "type",
                },
                {
                    text: "Message",
                    align: "left",
                    sortable: false,
                    value: "message",
                },
                {
                    text: "Keyword",
                    align: "left",
                    sortable: true,
                    value: "keyword",
                },
                {
                    text: "Short Code",
                    align: "left",
                    sortable: true,
                    value: "shortCode",
                },
                {
                    text: "Date Sent",
                    align: "left",
                    sortable: true,
                    value: "dateSent",
                },
                {
                    text: "Status",
                    align: "left",
                    sortable: true,
                    value: "deliveryStatus",
                    filter: this.deliveryStatusFilter,
                },
                {
                    text: "Date Delivered",
                    align: "left",
                    sortable: true,
                    value: "dateDelivered",
                },
                {
                    text: "Status Description",
                    align: "left",
                    sortable: true,
                    value: "errorCode",
                },
                {
                    text: "Application",
                    align: "left",
                    sortable: true,
                    value: "application",
                    filter: this.applicationFilter,
                },
                {
                    text: "Company",
                    align: "left",
                    sortable: true,
                    value: "company",
                    filter: this.companyFilter,
                },
            ];
        },
        subscriptionHeaders() {
            return [{
                    text: "Keyword",
                    align: "left",
                    sortable: true,
                    value: "keyword",
                },
                {
                    text: "Short code",
                    align: "left",
                    sortable: true,
                    value: "shortCode",
                },
                {
                    text: "Migration Date",
                    align: "left",
                    sortable: true,
                    value: "migrationDate",
                },
                {
                    text: "Unsub Date",
                    align: "left",
                    sortable: true,
                    value: "unsubscriptionDate",
                },
            ];
        },
    },
    methods: {

        Filter() {

            this.FilteredResults = this.getUserHistory

            let UserHistoryAfterShortCodeFilter = [];
            let UserHistoryAfterDeliveryStatusFilter = [];

            var ShortCodeFilterSelectedItems = this.filterShortCodesModel + '';
            let SearchField = 'shortCode';
            let splitQ = ShortCodeFilterSelectedItems.split(',').map(sq => sq);

            //Apply SC Filter
            UserHistoryAfterShortCodeFilter = this.FilteredResults.filter(
                s => splitQ.find(sq => s[SearchField].indexOf(sq) > -1) !== undefined);

            if (UserHistoryAfterShortCodeFilter.length == 0) {
                UserHistoryAfterShortCodeFilter = this.FilteredResults
            }

            var DeliveryStatusSelectedItems = this.filterDeliveryStatusModel + '';
            SearchField = 'deliveryStatus';
            splitQ = DeliveryStatusSelectedItems.split(',').map(sq => sq);

            //Apply Delivery Status Filter
            UserHistoryAfterDeliveryStatusFilter = UserHistoryAfterShortCodeFilter.filter(
                s => splitQ.find(sq => s[SearchField].indexOf(sq) > -1) !== undefined);

            if (UserHistoryAfterShortCodeFilter.length > 0) {
                this.FilteredResults = UserHistoryAfterShortCodeFilter
            }

            if (UserHistoryAfterDeliveryStatusFilter.length > 0) {
                this.FilteredResults = UserHistoryAfterDeliveryStatusFilter
            }

            // Return Empty Result Set if filter combination returns no results
            if (UserHistoryAfterShortCodeFilter.length > 0 &&
                UserHistoryAfterDeliveryStatusFilter.length == 0 &&
                DeliveryStatusSelectedItems &&
                DeliveryStatusSelectedItems != 'null'
            ) {
                this.FilteredResults = UserHistoryAfterDeliveryStatusFilter
            }

            ShortCodeFilterSelectedItems = undefined
            DeliveryStatusSelectedItems = undefined

            return this.FilteredResults
        },

        ...mapMutations({
            setMSISDN: "user/setMSISDN",
            updateBannedUser: "user/updateBannedUser",
        }),
        ...mapActions({
            login: "auth/login",
            fetchBannedUser: "user/fetchBannedUser",
            fetchUserHistory: "user/fetchUserHistory",
            fetchUserSubscriptions: "user/fetchUserSubscriptions",
            banUser: "user/banUser",
        }),
        updateField(value, fieldName) {
            this.updateBannedUser({
                [fieldName]: value
            });
        },
        validateKey(key) {
            const isEnterKey = ["Enter"].indexOf(key.code) > -1;

            if (isEnterKey === true) this.handleUserHistory();
            else this.validateMsidn();
        },
        validateMsidn() {
            this.MSISDN = this.MSISDN.replace(/\D/g, "");

            if (
                this.MSISDN.length == 12 &&
                this.MSISDN.match(/^[3]{1}[0]{1}[6]{1}[9]{1}[0-9]{8}$/)
            )
                this.isFormValid = true;
            else this.isFormValid = false;
        },
        handleUserHistory() {

            this.FilteredResults = [];

            if (!this.isFormValid) {
                this.showErrorMessage();
            } else {

                this.loading = true;

                this.fetchBannedUser().then(() => {});

                this.fetchUserSubscriptions().then(() => {});

                this.fetchUserHistory().then(() => {

                    this.loading = false;
                    this.export_json_show = false;
                    if (this.getUserHistory.length > 0) {

                        this.FilteredResults = this.getUserHistory

                        const distinct = (value, index, self) => {
                            return self.indexOf(value) === index;
                        }

                        let tmp_ShortCodes = [];

                        for (var j = 0; j < this.FilteredResults.length; j++) {
                            tmp_ShortCodes.push(this.FilteredResults[j]['shortCode'])
                        }

                        tmp_ShortCodes = tmp_ShortCodes.filter(distinct)

                        for (var x = 0; x < tmp_ShortCodes.length; x++) {
                            this.ShortCodeFilterItems.push({
                                "text": tmp_ShortCodes[x],
                                "value": tmp_ShortCodes[x]
                            })
                        }

                        let tmp_DeliveryStatus = [];

                        for (j = 0; j < this.FilteredResults.length; j++) {
                            tmp_DeliveryStatus.push(this.FilteredResults[j]['deliveryStatus'])
                        }

                        tmp_DeliveryStatus = tmp_DeliveryStatus.filter(distinct)

                        for (x = 0; x < tmp_DeliveryStatus.length; x++) {
                            this.deliveryStatusFilterItems.push({
                                "text": tmp_DeliveryStatus[x],
                                "value": tmp_DeliveryStatus[x]
                            })
                        }

                        this.export_json_show = true;
                    }
                    this.export_json_data = this.getUserHistory;
                    this.export_json_worksheet = this.getMSISDN;
                    this.export_json_filename =
                        "paydash_export_" + this.getMSISDN + ".xlsx";
                });
            }
        },
        handleBannedUser() {
            this.banUser().then((response) => {
                if (response == "OK") this.showBanMessage();

                this.fetchBannedUser();
            });
        },
        showErrorMessage() {
            this.snackbarText = "Please enter a valid MSISDN - 3069xxxxxxxx";
            this.snackbarColour = "red darken-2";
            this.snackbar = true;
        },
        showBanMessage() {
            this.snackbarText =
                "User " + this.getMSISDN + " has been successfully banned!";
            this.snackbarColour = "green darken-2";
            this.snackbar = true;
        },

        deliveryStatusFilter(value) {
            if (!this.filterDeliveryStatus) {
                return true;
            }

            return value === this.filterDeliveryStatus;
        },
        applicationFilter(value) {
            if (!this.filterApplication) {
                return true;
            }

            return value.toLowerCase().includes(this.filterApplication.toLowerCase());
        },
        companyFilter(value) {
            if (!this.filterCompany) {
                return true;
            }

            return value.toLowerCase().includes(this.filterCompany.toLowerCase());
        },
        clearFilters() {

            this.filterShortCodesModel = null;
            this.filterDeliveryStatusModel = null;

            this.filterApplication = '';
            this.filterCompany = '';

            this.FilteredResults = this.getUserHistory;
        },
    },
    mounted() {
        this.login().then((response) => {
            if (response.status == "OK") this.validateMsidn();
            else this.$router.push({
                name: "Login"
            });
        });
    },
};
</script>

<style lang="scss">
#user-history-data-table {
    margin-top: 50px;

    thead {
        tr {
            height: 40px;

            th {
                white-space: nowrap;
                font-size: 13px;
            }
        }
    }

    tbody {
        tr {
            cursor: pointer;

            td {
                &.main-column {
                    text-align: left;
                    font-size: 12px;
                }

                &.sub-column {
                    text-align: left;
                    font-size: 11px;
                    color: gray;
                }

                &.status-column {
                    text-align: left;
                    text-transform: capitalize;
                    font-size: 11px;
                }
            }

            div {
                &.main-column {
                    text-align: left;
                    font-size: 12px;
                }

                &.sub-column {
                    text-align: left;
                    font-size: 11px;
                    color: gray;
                }
            }
        }
    }

    .status-color {
        padding: 4px;
        display: inline-block;
        border-radius: 10px;
        margin-right: 2px;
    }
}
</style>
