<template>
    <nav>
        <v-app-bar
            dense
            app
        >
        <!-- <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon> -->
        <v-toolbar-title>Paydash Support</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="toggleDark()">
            <v-icon>mdi-invert-colors</v-icon>
        </v-btn>
        <v-btn class="ml-3 mr-1" v-if="$route.name=='Home'" @click="logout()">
            Logout
        </v-btn>
        </v-app-bar>

        <!-- <v-navigation-drawer
            v-model="drawer"
            app
        >
            <MenuList/>
        </v-navigation-drawer> -->
    </nav>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex'

export default {

    data() {
        return {
            drawer: false
        }
    },
    computed: {
        ...mapGetters ({
            getDark: "app/getDark"
        }),
    },
    methods: {
        ...mapMutations ({
            setDark: "app/setDark",
            setMSISDN: "user/setMSISDN",
            setBannedUser: "user/setBannedUser",
            setUserHistory: "user/setUserHistory"
        }),
        toggleDark() {
            this.$vuetify.theme.dark = !this.getDark
            this.setDark(!this.getDark)
        },
        setTheme() {
            this.$vuetify.theme.dark = this.getDark
        },
        logout() {
            this.setMSISDN("")
            this.setBannedUser({})
            this.setUserHistory([])
            this.$router.push({name: "Login"})
        },
    },
    mounted() {
        this.setTheme()
    }
}
</script>