import userApi from '../plugins/api/userApi'

export default {
  namespaced: true,
  strict : true,

  state: {
    MSISDN: "",
    user: {},
    history: [],
    subscriptions: [],
  },
  getters: {
    getMSISDN(state) {
      return state.MSISDN
    },
    getBannedUser: (state) => {
      return state.user
    },
    getHistory: (state) => {
        return state.history
    },
    getSubscriptions: (state) => {
      return state.subscriptions
    }
  },
  mutations: {
    setMSISDN: (state, payload) => {
      state.MSISDN = payload
    },
    setBannedUser: (state, payload) => {
      state.user = payload
    },
    updateBannedUser: (state, payload) => {
      state.user = Object.assign({}, state.user, payload);
    },
    setUserHistory: (state, payload) => {
      state.history = payload
    },
    setUserSubscriptions: (state, payload) => {
      state.subscriptions = payload
    }
  },
  actions: {
    fetchUserHistory: ({getters, commit}) => {
      commit("setUserHistory", [])
      return userApi.fetchUserHistory(getters.getMSISDN, "")
      .then((response) => {
        commit("setUserHistory", response.payload.rows)
        return response.payload.rows
      })
      .catch((error) => {
        return error
      })
    },
    fetchUserSubscriptions: ({getters, commit}) => {
      commit("setUserSubscriptions", [])
      return userApi.fetchUserSubscriptions(getters.getMSISDN, "")
      .then((response) => {
        commit("setUserSubscriptions", response.payload.rows)
        return response.payload.rows
      })
      .catch((error) => {
        return error
      })
    },
    fetchBannedUser: ({getters, commit}) => {
      return userApi.fetchBannedUser(getters.getMSISDN, "")
      .then((response) => {
        commit("setBannedUser", response.payload)
        return response.payload
      })
      .catch((error) => {
        return error
      })
    },
    banUser: ({getters}) => {
      if(getters.getBannedUser.isBanned)
        return ""

      let data = {
        MSISDN: getters.getMSISDN,
        reason: 2,
        comments: getters.getBannedUser.comments
      }

      return userApi.banUser(data, "")
      .then(() => {
        return "OK"
      })
      .catch((error) => {
        return error
      })
    },
  }
}